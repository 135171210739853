<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <!-- <img
              class=" logo"
              src="../../public/assets/icon/logo-alloo-pharma.svg"
              alt="Alloopharma icon"
          /> -->
          <div class="maxWidth">
            <ion-text class="regular cWhite ion-no-margin ion-text-center">
              <div style="display: flex; align-items: center; justify-content: center;" class="ion-text-center">
                <h1 class="ion-text-center semiBold firstName">
                  Bonjour {{ firstName || "à toi" }} {{ isPharmacist ? '' : '😍' }}
                </h1>
                <!-- <ProfilePicture v-if="!isPharmacist " :user="user" @click="updateProfilePicture"/> -->
              </div>
              <h4 class="ion-text-center ">
                <span
                    class="menuTitleFont ">{{
                    isPharmacist ? 'Au service de votre pharmacie !' : 'des gros coeff !'
                  }}</span>
              </h4>
            </ion-text>
            <div class="enfant" style="height: 110px" v-if="isPharmacist"/>
            <ion-card class="enfant" v-else>
              <ion-card-content class="card">
                <div class="flex-container space-around">
                  <div class="p-top">
                    <ion-item lines="none" class="center">
                      <img
                          alt="euro"
                          class="icon center"
                          src="../../public/assets/icon/euro.svg"
                      />
                    </ion-item>
                    <ion-item lines="none" class="center">
                      <ion-text class="center gainTitle">{{ totalGain || 0 }}€</ion-text>
                    </ion-item>
                  </div>
                  <div class="verticalLine"></div>
                  <div class="p-top">
                    <ion-item lines="none">
                      <img
                          alt="sandglass"
                          class="icon iconW center"
                          src="../../public/assets/icon/sandglass.svg"
                      />
                    </ion-item>
                    <ion-item text-center lines="none">
                      <ion-text class="center gainTitle"
                      >{{ waitingGain || 0 }}€
                      </ion-text
                      >
                    </ion-item>
                  </div>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
        <div class="maxWidth">
          <!--  Pharmacist content -->
          <div v-if="isPharmacist">
            <div v-if="!isPharmacyContacted">
              <ion-text class="ion-margin ion-text-center">
                <h1 class="semiBold">Merci pour votre inscription,<br> vous serez contacté d'ici 1h 📞</h1>
              </ion-text>
            </div>
            <GoToInfoPharmacy v-if="!isPharmacyComplete"/>
            <UsersOpinion v-if="isPharmacyComplete"/>
            <SignSepa v-if="isPharmacyComplete && !isIBANUploaded "/> 
            <!-- && !isIBANUploaded -->
          </div>
          <!--  Pharmacist content -->

          <!--  User content -->
          <div v-else>
            <InfoDashboard/>
            <AddressField class="maxWidth"></AddressField>
            <ion-card class="cardNextMission">
              <ion-card-header>
                <ion-card-title class="titleNextMission text-white">
                  Ma prochaine mission
                </ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-no-padding">
                <div v-if="mission">
                  <ConfirmedMission
                      :mission-id="mission.missionId"
                      :lng="mission.longitude"
                      :lat="mission.latitude"
                      :pharmacyName="mission.pharmacyName"
                      :day="mission.day"
                      :increase="mission.increase"
                      :date-start="mission.beginTime"
                      :date-end="mission.endTime"
                      :salary="mission.salary"
                      :title="mission.title"
                      :rest="mission.pauseHours"
                      :address="mission.address"
                      :distance="mission.distance"
                      :vip-type="mission.vipType"
                      :is-flexible="mission.isFlexible"
                      :worked-hours="mission.workedHours"
                      :can-display-map="true"
                  >
                  </ConfirmedMission>
                </div>
                <div v-else>
                  <ion-text class="text-white">
                    <h2>Tu n'as encore aucune mission de prévue 😅</h2>
                    <div
                        class="goMissions pointer"
                        v-on:click="goMissions()"
                    >
                      Go 🚀 Des missions !
                    </div>
                  </ion-text>
                </div>
              </ion-card-content>
            </ion-card>
            <div class="ion-margin-top">
              <ion-card>
                <ion-card-header>
                  <ion-card-title color="primary" class="titleNextMission">Parrainage</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <img
                      class="ion-margin-bottom giftbox"
                      src="../../public/assets/icon/giftbox.svg"
                      alt="giftbox"
                  />
                  <ion-text color="ion-text-left text-white">
                    <h2>Comment parrainer tes amis ?</h2>
                    <p>1. Envoie ton lien de parrainage.</p>
                    <p>2. Ton ami s'inscrit et réalise sa première mission.</p>
                    <p>3. Bravo ! {{ sponsorshipGain }}€ gagné.</p>
                  </ion-text>

                  <ion-button @click="copyLink">
                    Inviter mes amis 💗 {{ sponsorshipGain }}€
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </div>
          </div>
          <!--  User content -->
          <div style="display: flex; align-items: flex-end; justify-content: center" class="call">
            <ion-button color="success" @click="toggleTel">
              📞 Contactez-nous - 01 86 65  29 61

            </ion-button>
            <div style="margin-right: 30px; margin-left: 30px">
              <ion-button v-if="!isShowNum" color="danger" @click="toggleTel">
                Annuler une mission - 01 86 65  29 61
              </ion-button>
              <ion-button v-else color="danger" @click="toggleTel">
                {{ phoneAlloopharma }}
              </ion-button>
            </div>
            <ion-button class="cYellow cBlack" href="https://alloopharma.co/foire-aux-questions/">
              FAQ
            </ion-button>
            <ion-button href="https://alloopharma.co/tarifs/" style="margin-left: 30px" v-if="isPharmacist">
              Tarifs
            </ion-button>
          </div>
        </div>
        <div v-if="!isDesktop" class="footerBackground">
          <img
              class="ion-margin-bottom logoFooter"
              src="../../public/assets/icon/logo-alloo-pharma.svg"
              alt="Alloopharma logo"
          />
        </div>
        <div v-else class="footerNotMobile">
          <img
              class="ion-margin-bottom logoFooter"
              src="../../public/assets/icon/alloopharma-logo.png"
              alt="Alloopharma logo"
          />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {Clipboard} from '@capacitor/clipboard';
import moment from "moment";
import router from "@/router";
import store from "@/store";
import {cameraOutline} from 'ionicons/icons';
import {Camera, CameraResultType} from '@capacitor/camera';
import ConfirmedMission from "@/components/ConfirmedMission.vue";
import AddressField from "@/components/AddressField.vue";
import InfoDashboard from "@/components/InfoDashboard.vue";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonItem,
  IonPage,
  IonText,
  toastController,
} from "@ionic/vue";
import {getDashboard, getShifts, updateProfile, updateProfileWithFormData} from "@/services/api";
import GoToInfoPharmacy from "@/components/GoToInfoPharmacy";
import UsersOpinion from "@/components/UsersOpinion";
import SignSepa from "@/components/SignSepa";
import ProfilePicture from "@/components/ProfilePicture";
import {PushNotifications} from "@capacitor/push-notifications";


export default {
  name: "Dashboard",
  components: {
    // ProfilePicture,
    SignSepa,
    UsersOpinion,
    GoToInfoPharmacy,
    ConfirmedMission,
    AddressField,
    InfoDashboard,
    IonItem,
    IonContent,
    IonPage,
    IonCard,
    IonText,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonButton,
  },
  setup() {
    return {
      cameraOutline,
    }
  },
  data() {
    return {
      isShowNum: false,
    };
  },
  computed: {
    isDesktop() {
      return store.getters.isDesktop;
    },
    profilePicture() {
      return store.getters.profilePicture?.url;
    },
    user() {
      return store.getters.user;
    },
    isPharmacist() {
      return store.getters.isPharmacist;
    },
    isPharmacyComplete() {
      return store.getters.isPharmacyComplete;
    },
    isPharmacyContacted() {
      return store.getters.isPharmacyContacted;
    },
    isIBANUploaded() {
      return store.getters.user.IBAN.url != null;
    },

    mission() {
      const mission = store.getters.lastSlotNextConfirmed;

      if (!mission || mission.id === 0) return null;
      const endDate = moment(mission.day, "YYYY-MM-DD").locale('fr').format('D MMM YYYY')
      const beginTime = mission.beginTime.split("T")[1].substring(0, 5)
      const endTime = mission.endTime.split("T")[1].substring(0, 5)
      mission.dateTimeMissionConfirmed = {
        date: endDate,
        timeTable: `${beginTime} -> ${endTime}`
      }
      return mission;
    },
    address() {
      return store.getters.address;
    },
    totalGain() {
      return store.getters.totalGain;
    },
    waitingGain() {
      return store.getters.waitingGain;
    },
    firstName() {
      const firstName = store.getters.firstName;
      if (!firstName) {
        return null;
      }
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    },
    sponsorshipLink() {
      return store.getters.sponsorshipLink;
    },
    sponsorshipGain() {
      return store.getters.sponsorshipGain;
    },
    phoneAlloopharma() {
      return store.getters.phoneAlloopharma;
    }
  },
  methods: {
    async updateProfilePicture() {
      if (!store.getters.isPharmacist && this.profilePicture) {
        await this.displayCannotChangeProfilePicture();
        return;
      }

      Camera.getPhoto({
        allowEditing: true,
        quality: 100,
        width: 200,
        height: 200,
        webUseInput: true,
        resultType: CameraResultType.Uri,
        promptLabelCancel: "Annuler",
        promptLabelPhoto: "Choisir une photo 📂",
        promptLabelPicture: "Prendre une photo 🤳",
        promptLabelHeader: "Choisir une photo de profil",
      }).then(async (result) => {
        let blob = await fetch(result.webPath).then(r => r.blob());
        blob = blob.slice(0, blob.size, "image/" + result.format)
        const formData = new FormData();
        formData.append('profile_picture', blob);
        updateProfileWithFormData(formData).catch((e) => console.log(e));
      })
    },
    toggleTel() {
      // this.isShowNum = !this.isShowNum;
      // if (!this.isShowNum) {
        window.open("tel:" + this.phoneAlloopharma, "_self");
      // }
    },
    goMissions() {
      router.push("missions");
    },
    async copyLink() {
      await Clipboard.write({
        string: this.sponsorshipLink
      });
      await this.displayMsgLinkCopied();
    },
    async displayMsgLinkCopied() {
      const toast = await toastController.create({
        message: "Ton lien de parrainage a été copié",
        duration: 4000,
        color: 'success'
      });
      return toast.present();
    },

    async displayCannotChangeProfilePicture() {
      const toast = await toastController.create({
        message: "Tu ne peux pas changer de photo de profil",
        duration: 4000,
        color: 'danger'
      });
      return toast.present();
    }
  },

  ionViewDidEnter() {
    getDashboard().then(async (res) => {
      console.log(res)
      const user = store.getters.user;
      console.log(user)
      const {phone, firstName, lastName, email, id, role} = user;
      if (role === 'user') {
        await getShifts();
      }
      window.$crisp.push(["do", "chat:show"])
      window.$crisp.push(["safe", true])
      window.$crisp.push(["config", "container:index", [1]]);
      window.$crisp.push(["set", "user:email", [email]]);
      window.$crisp.push(["set", "user:phone", [phone]]);
      window.$crisp.push(["set", "user:nickname", [`${firstName} ${lastName}`]]);
      window.$crisp.push(["set", "session:data", [[["User_ID", id]]]]);
    })

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token) => { // Token
          updateProfile({deviceToken: token.value})
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        () => { // any
          updateProfile({deviceToken: 'not accepted'})
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (actionPerformed) => { // ActionPerformed
          const data = actionPerformed.notification.data;
          if (data.route) {
            if (data.id) {
              store.dispatch('setCurrentMission', { id: data.id });
            }
            router.push(data['route'])
          }
        }
    );
  }
};
</script>
<style scoped>
.main {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  /*height: 100vh !important;*/
}

.cYellow {
  --background: #ffce8f
}

h3 {
  margin-bottom: 0;
}

h6 {
  margin-top: 0;
}

.gainTitle {
  font-size: 32px !important;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

img {
  width: 50px;
}

ion-card {
  border-radius: 35px;
  --background: var(--new-blue, var(--new-blue, var(--new-blue, rgb(247, 253, 255))));
  box-shadow: rgb(0 0 0 / 4%) 0 3px 1px -2px, rgb(0 0 0 / 24%) 0 2px 2px 0, rgb(0 0 0 / 22%) 0 1px 5px 0
}

ion-item {
  --background: var(--new-blue, var(--new-blue, var(--new-blue, rgb(247, 253, 255))));
}

.logo {
  width: 140px;
  margin-top: 28px;
  margin-bottom: 28px;
}

.p-top {
  padding-top: 10px;
}

.iconW {
  fill: red;
}

.card {
  padding: 0;
}

.sponsorship {
  background-color: var(--ion-color-success);
  padding: 5px;
}

.verticalLine {
  border-left: 0.5px solid rgb(238, 238, 238);
  font-weight: lighter;
  height: 100%;
  position: absolute;
  margin-top: 5px;
}

.call {
  margin-top: 21px;
  font-size: 35px;
  padding: 10px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.goMissions {
  background-color: var(--ion-color-primary);
  margin-top: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--ion-color-light);
  text-decoration: underline;
}



.space-around {
  justify-content: space-around;
}

.icon {
  margin-top: 10px;
}

.stats {
  margin-top: 20px;
  margin-bottom: 2px;
}

.center {
  margin: auto;
}

.itemLinkCopied {
  margin: auto;
  padding: 0;
  width: 90%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.titleNextMission {
  font-size: 24px;
}

.giftbox {
  width: 75px;
}

.cardNextMission {
  margin-top: 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}

.sponsorshipLink {
  background-color: black;
  color: var(--ion-color-light);
  border-radius: 30px;
  display: table-cell;
  margin: auto !important;
  padding: 3px;
}

.enfant {
  margin-bottom: 80px;
  top: 40px;
  margin-top: -30px;
}

.firstName {
  font-size: 38px;
}

</style>
